import skyline_img from "../components/assets/images/footer/skyline-white-logo.svg";
import fb from "../components/assets/images/footer/fb.webp";
import insta from "../components/assets/images/footer/insta.webp";
import indeed from "../components/assets/images/footer/indeed.webp";
import youtube from "../components/assets/images/footer/youtube.webp";
import location_icon from "../components/assets/images/footer/location_icon.webp";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* footer section */}
      <section
        className="section"
        style={{ paddingBottom: "0px", paddingTop: "0px" }}
      >
        <footer className="footer-background">
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <img
                    className="img-fluid"
                    style={{ width: 224, height: 71 }}
                    src={skyline_img}
                    alt="skyline"
                  />
                </div>
                <div className="col-md-3 mt-4 border-right">
                  <h2
                    className="mt-3 mb-4"
                    style={{
                      fontSize: 18,
                      color: "#ffffff",
                      fontWeight: 600,
                    }}
                  >
                    About Us
                  </h2>
                  <h5
                    className=""
                    style={{
                      fontSize: 13,
                      color: "#ffffff",
                      lineHeight: 2,
                      fontWeight: 400,
                    }}
                  >
                    Skyline Infosys is a global outsourcing partner that
                    empowers small and medium-scale businesses with value-driven
                    outsourcing web application development services. Our team
                    thinks globally to deliver the best solutions for your
                    outsourcing needs. Join hands with us and unlock your
                    business's potential for growth and success.
                  </h5>
                </div>
                <div className="col-md-3 mt-4 border-right">
                  <h2
                    className="mt-3"
                    style={{
                      fontSize: 18,
                      color: "#ffffff",
                      fontWeight: 600,
                      marginBottom: 36,
                    }}
                  >
                    Career
                  </h2>
                  <Link to="/career">
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#ffffff",
                        lineHeight: 1,
                      }}
                    >
                      Hire React Js Developer
                    </h1>
                  </Link>
                  <br />
                  <Link to="/career">
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#ffffff",
                        lineHeight: 1,
                      }}
                    >
                      Hire Node Js Developer
                    </h1>
                  </Link>
                  <br />
                  <Link to="/career">
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#ffffff",
                        lineHeight: 1,
                      }}
                    >
                      Hire PHP Developer
                    </h1>
                  </Link>
                  <br />
                  <Link to="/career">
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#ffffff",
                        lineHeight: 1,
                      }}
                    >
                      Hire Laravel Developer
                    </h1>
                  </Link>
                  <br />
                  <Link to="/career">
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#ffffff",
                        lineHeight: 1,
                      }}
                    >
                      Hire Mern Stack Developer
                    </h1>
                  </Link>
                </div>
                <div className="col-md-3 mt-4 border-right">
                  <h2
                    className="mt-3"
                    style={{
                      fontSize: 18,
                      color: "#ffffff",
                      fontWeight: 600,
                      marginBottom: 26,
                    }}
                  >
                    Our Branches
                  </h2>
                  <h5 style={{ fontSize: 16, lineHeight: 2, color: "#efefef" }}>
                    <img
                      style={{ width: "16px", marginRight: "10px" }}
                      src={location_icon}
                      alt=""
                    />
                    Surat, Gujarat
                  </h5>
                  <h5
                    className=""
                    style={{ fontSize: 14, lineHeight: 2, color: "#efefef" }}
                  >
                 

                    B-805 to 809, IT Park, Opp. Gajera International School (Digital Valley), Surat, Gujarat 394101
                  </h5>
                  {/* <div className="mt-4">
                    <h5
                      style={{ fontSize: 16, lineHeight: 2, color: "#efefef" }}
                    >
                      <img
                        style={{ width: "16px", marginRight: "10px" }}
                        src={location_icon}
                        alt=""
                      />
                      Indore, Madhya Pradesh
                    </h5>
                    <h5
                      className=""
                      style={{ fontSize: 14, lineHeight: 2, color: "#efefef" }}
                    >
                      1st Floor, Pramukh Plaza, Vijay Nagar, Indore, Madhya
                      Pradesh, India.
                    </h5>
                  </div> */}
                </div>
                <div className="col-md-3 mt-4">
                  <h2
                    className="mt-3"
                    style={{
                      fontSize: 16,
                      color: "#ffffff",
                      fontWeight: 600,
                      marginBottom: 26,
                    }}
                  >
                    Contact Us
                  </h2>
                  <h5
                    className=""
                    style={{ fontSize: 16, lineHeight: 2, color: "#efefef" }}
                  >
                    Phone No :- +91 8128996966
                  </h5>
                  <h5
                    className="mb-4 mt-3"
                    style={{ fontSize: 14, lineHeight: 2, color: "#efefef" }}
                  >
                    Email :- info@skylineinfosys.com
                  </h5>
                  <a
                    className="mr-2"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.facebook.com/skylineinfosys"
                  >
                    <img style={{ width: 38 }} src={fb} alt="skyline" />
                  </a>
                  <a
                    className="mr-2"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.instagram.com/skyline_infosys/"
                  >
                    <img style={{ width: 38 }} src={insta} alt="skyline" />
                  </a>
                  <a
                    className="mr-2"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/skylineinfosys/"
                  >
                    <img style={{ width: 38 }} src={indeed} alt="skyline" />
                  </a>

                  <a
                    className="mr-2"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCGoXxHlahdWs4eyDnw1C1gA"
                  >
                    <img style={{ width: 38 }} src={youtube} alt="skyline" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container border-top text-center border-white py-4">
            <div className="footer-bottom">
              <p className="mb-0 text-white">
                © 2015 - {new Date().getFullYear()} Skyline infosys. All rights
                reserved{" "}
              </p>
              <ul className="footer-ui">
                {/* <li style={{ padding: "0px 10px" }}>
                  <a href="./privacy-policy" target="_blank" className="pt-1">
                    <span className="text-white">Privacy&nbsp;Policy</span>
                  </a>
                </li>
                <h5 className="text-white">|</h5>
                <li style={{ padding: "0px 10px" }}>
                  <a
                    href="./terms-and-conditions"
                    target="_blank"
                    className="pt-1"
                  >
                    <span className="text-white">
                      Terms&nbsp;&amp;&nbsp;Conditions
                    </span>
                  </a>
                </li>
                <h5 className="text-white">|</h5>
                <li style={{ padding: "0px 10px" }}>
                  <a href="./our-sitemap" target="_blank" className="pt-1">
                    <span className="text-white">Sitemap</span>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </footer>
        <div
          className="backtotop"
          style={{ marginBottom: 30, cursor: "pointer" }}
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <div id="taketotop" className="myrocket" />
        </div>
      </section>
    </>
  );
};

export default Footer;
