import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";

import { TRACKING_ID } from "./config";

import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Loading } from "./components/Loading";

import DemandProgrammingPage from "./components/Blog/Page/demand_programing_page.js";

const Home = lazy(() => import("./components/Home/Home"));
const Career = lazy(() => import("./components/Career/Career"));
const Blog = lazy(() => import("./components/Blog/Blog"));
const LifeSkyline = lazy(() => import("./components/LifeSkyline/LifeSkyline"));
const About = lazy(() => import("./components/About/About"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Portfolio = lazy(() => import("./components/Portfolio/Portfolio"));
const MobileApplicationDevelopment = lazy(() =>
  import(
    "./components/Services/components/MobileAppDev/MobileApplicationDevelopment"
  )
);
const ReactJs = lazy(() => import("./components/Techonology/frontEnd/ReactJS"));
const AngularJS = lazy(() =>
  import("./components/Techonology/frontEnd/AngularJS")
);
const CSS3 = lazy(() => import("./components/Techonology/frontEnd/CSS3"));
const Javascript = lazy(() =>
  import("./components/Techonology/frontEnd/Javascript")
);
const VueJS = lazy(() => import("./components/Techonology/frontEnd/VueJS"));
const HTML5 = lazy(() => import("./components/Techonology/frontEnd/HTML5"));
const CodeIgniter = lazy(() =>
  import("./components/Techonology/backEnd/Codeigniter")
);
const Java = lazy(() => import("./components/Techonology/backEnd/Java"));
const PHP = lazy(() => import("./components/Techonology/backEnd/PHP"));
const NodeJs = lazy(() => import("./components/Techonology/backEnd/NodeJS"));
const Laravel = lazy(() => import("./components/Techonology/backEnd/laravel"));
const DotNet = lazy(() => import("./components/Techonology/backEnd/dotNET"));
const MongoDB = lazy(() => import("./components/Techonology/dataBase/mongoDB"));
const MySQL = lazy(() => import("./components/Techonology/dataBase/mySQL"));
const PostgreSQL = lazy(() =>
  import("./components/Techonology/dataBase/postgreSQL")
);
const Android = lazy(() => import("./components/Techonology/mobile/Android"));
const Flutter = lazy(() => import("./components/Techonology/mobile/Flutter"));
const IOS = lazy(() => import("./components/Techonology/mobile/iOS"));

const ReactVsAngularPage = lazy(() =>
  import("./components/Blog/Page/react_vs_angular_page.js")
);
const PhpAdvantagesPage = lazy(() =>
  import("./components/Blog/Page/php_advantages_page.js")
);
const JavaKotlinPage = lazy(() =>
  import("./components/Blog/Page/java_vs_kotlin_page.js")
);
const PHPFramework = lazy(() =>
  import("./components/Blog/Page/php_framwork_page.js")
);
const CodingVsProgrammingPage = lazy(() =>
  import("./components/Blog/Page/coding_vs_programing_page.js")
);
const ReactVsFlutterPage = lazy(() =>
  import("./components/Blog/Page/react_vs_flutter_page.js")
);
const WebDesignDevelopmentPage = lazy(() =>
  import("./components/Blog/Page/webdesign_development_page.js")
);
// const DemandProgrammingPage = lazy(() =>
//   import("./components/Blog/Page/demand_programing_page.js")
// );

const PageNotFound = lazy(() => import("./components/404/PageNotFound"));
const WebDevelopment = lazy(() =>
  import("./components/Services/components/WebDev/WebDevelopment")
);
const CustomSoftware = lazy(() =>
  import("./components/Services/components/CustomSoft/CustomSoftware")
);
const QaTesting = lazy(() =>
  import("./components/Services/components/QaTesting/QaTesting")
);

const SupportMaintenance = lazy(() =>
  import(
    "./components/Services/components/SupportMaintenance/SupportMaintenance"
  )
);
const StaffAugmentation = lazy(() =>
  import("./components/Services/components/StaffAugmentation/StaffAugmentation")
);
const Cloud = lazy(() =>
  import("./components/Services/components/Cloud/Cloud")
);
const DevOps = lazy(() =>
  import("./components/Services/components/DevOps/DevOps")
);
const ArtificialIMachineLearning = lazy(() =>
  import(
    "./components/Services/components/ArtificialIMachineLearning/ArtificialIMachineLearning"
  )
);
const Partnership = lazy(() => import("./components/Partnership/Partnership"));

ReactGA.initialize(TRACKING_ID);

function App() {
  //google analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Suspense fallback={<Loading isCenter />}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/life-at-skyline" element={<LifeSkyline />} />
            <Route exact path="/about-us" element={<About />} />
            <Route exact path="/contact-us" element={<Contact />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/partnership" element={<Partnership />} />

            {/* technology frontend */}

            <Route
              exact
              path="/technology/reactjs-development-services"
              element={<ReactJs />}
            />
            <Route
              exact
              path="/technology/angularjs-development-services"
              element={<AngularJS />}
            />
            <Route
              exact
              path="/technology/vuejs-development-services"
              element={<VueJS />}
            />
            <Route
              exact
              path="/technology/css-web-development"
              element={<CSS3 />}
            />
            <Route
              exact
              path="/technology/html5-development-services"
              element={<HTML5 />}
            />
            <Route
              exact
              path="/technology/javascript-development-services"
              element={<Javascript />}
            />

            {/* technology backend */}

            <Route
              exact
              path="/technology/dot-net-development-services"
              element={<DotNet />}
            />
            <Route
              exact
              path="/technology/codeigniter-development-services"
              element={<CodeIgniter />}
            />
            <Route exact path="/technology/java-development-services" element={<Java />} />
            <Route
              exact
              path="/technology/laravel-web-development-services"
              element={<Laravel />}
            />
            <Route
              exact
              path="/technology/node-js-development-service"
              element={<NodeJs />}
            />
            <Route exact path="/technology/php-website-development-services" element={<PHP />} />

            {/* technology database */}

            <Route
              exact
              path="/technology/mongodb database"
              element={<MongoDB />}
            />
            <Route
              exact
              path="/technology/postgresql database"
              element={<PostgreSQL />}
            />
            <Route
              exact
              path="/technology/mysql-database"
              element={<MySQL />}
            />

            {/* technology mobile */}

            <Route
              exact
              path="/technology/android-development-agency"
              element={<Android />}
            />
            <Route
              exact
              path="/technology/flutter-development-services"
              element={<Flutter />}
            />
            <Route exact path="/technology/ios-development-agency" element={<IOS />} />

            {/* service */}

            <Route
              exact
              path="/services/best-mobile-app-development-company"
              element={<MobileApplicationDevelopment />}
            />
            <Route
              exact
              path="/services/web-app-development-agency"
              element={<WebDevelopment />}
            />
            <Route
              exact
              path="/services/custom-healthcare-software-development"
              element={<CustomSoftware />}
            />
            <Route
              exact
              path="/services/software-testing-company"
              element={<QaTesting />}
            />
            <Route
              exact
              path="/services/software-maintenance-services"
              element={<SupportMaintenance />}
            />
            <Route
              exact
              path="/services/staff-augmentation-services"
              element={<StaffAugmentation />}
            />
            <Route
              exact
              path="/services/cloud-transformation-services"
              element={<Cloud />}
            />
            <Route
              exact
              path="/services/devops-development-services"
              element={<DevOps />}
            />
            <Route
              exact
              path="/services/ai-development-services"
              element={<ArtificialIMachineLearning />}
            />
            <Route
              exact
              path="/blog/angularjs-vs-reactjs"
              element={<ReactVsAngularPage />}
            />
            {/* blog routes start */}
            <Route
              exact
              path="/blog/advantages-of-php"
              element={<PhpAdvantagesPage />}
            />
            <Route
              exact
              path="/blog/java-vs-kotlin"
              element={<JavaKotlinPage />}
            />
            <Route
              exact
              path="/blog/best-programming-language-to-learn"
              element={<PHPFramework />}
            />
            <Route
              exact
              path="/blog/coding-vs-programing"
              element={<CodingVsProgrammingPage />}
            />
            <Route
              exact
              path="/blog/react-native-vs-flutter"
              element={<ReactVsFlutterPage />}
            />
            <Route
              exact
              path="/blog/php-frameworks"
              element={<DemandProgrammingPage />}
            />
            <Route
              exact
              path="/blog/web-design-and-development"
              element={<WebDesignDevelopmentPage />}
            />
            {/* blog routes end */}

            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </>
  );
}

export default App;
